<template>
  <div class="mb-3">
    <div class="card bg-light" v-if="!loading && hasEurBalances || hasGbpBalances">
      <div class="card-body p-2">
        <div class="row" v-if="hasGbpBalances">
          <div class="col">
            <div class="card rounded-0" style="border-top: 3px solid #28a745">
              <div class="card-body d-flex py-0 ps-0 pe-2">
                <span class="badge bg-success d-flex align-items-center rounded-0" style="width: 100px">Balance</span>
                <span class="ms-auto">£{{ gbpBalance }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="hasGbpBalances">
          <div class="col">
            <div class="card rounded-0" style="border-top: 3px solid #ffc107">
              <div class="card-body d-flex py-0 ps-0 pe-2">
                <span class="badge bg-warning d-flex align-items-center rounded-0" style="width: 100px">Pending</span>
                <span class="ms-auto">£{{ gbpPendingBalance }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="gbpOnHoldBalance > 0">
          <div class="col">
            <div class="card rounded-0" style="border-top: 3px solid #dc3545">
              <div class="card-body d-flex py-0 ps-0 pe-2">
                <span class="badge bg-danger d-flex align-items-center rounded-0" style="width: 100px">On Hold</span>
                <span class="ms-auto">£{{ gbpOnHoldBalance }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="hasEurBalances" :class="hasGbpBalances ? 'mt-3' : ''">
          <div class="col">
            <div class="card rounded-0" style="border-top: 3px solid #28a745">
              <div class="card-body d-flex py-0 ps-0 pe-2">
                <span class="badge bg-success d-flex align-items-center rounded-0" style="width: 100px">Balance</span>
                <span class="ms-auto">€{{ eurBalance }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="hasEurBalances">
          <div class="col">
            <div class="card rounded-0" style="border-top: 3px solid #ffc107">
              <div class="card-body d-flex py-0 ps-0 pe-2">
                <span class="badge bg-warning d-flex align-items-center rounded-0" style="width: 100px">Pending</span>
                <span class="ms-auto">€{{ eurPendingBalance }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="eurOnHoldBalance > 0">
          <div class="col">
            <div class="card rounded-0" style="border-top: 3px solid #dc3545">
              <div class="card-body d-flex py-0 ps-0 pe-2">
                <span class="badge bg-danger d-flex align-items-center rounded-0" style="width: 100px">On Hold</span>
                <span class="ms-auto">€{{ eurOnHoldBalance }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      balances: null
    };
  },
  methods: {
    fetchBalances() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/payments/adyen/balances").then(({ data }) => {
        this.balances = data.totalBalance;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  watch: {

  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    },
    gbpBalance() {
      if (this.balances) {
        let total = 0;
        this.balances.balance.forEach((item) => {
          if (item.currency === 'GBP') {
            total += item.value
          }
        });

        return (total / 100).toLocaleString('en-GB', {minimumFractionDigits: 2});
      }
    },
    gbpPendingBalance() {
      if (this.balances) {
        let total = 0;
        this.balances.pendingBalance.forEach((item) => {
          if (item.currency === 'GBP') {
            total += item.value
          }
        });

        return (total / 100).toLocaleString('en-GB', {minimumFractionDigits: 2});
      }
    },
    gbpOnHoldBalance() {
      if (this.balances) {
        let total = 0;
        this.balances.onHoldBalance.forEach((item) => {
          if (item.currency === 'GBP') {
            total += item.value
          }
        });

        return (total / 100).toLocaleString('en-GB', {minimumFractionDigits: 2});
      }
    },
    eurBalance() {
      if (this.balances) {
        let total = 0;
        this.balances.balance.forEach((item) => {
          if (item.currency === 'EUR') {
            total += item.value
          }
        });

        return (total / 100).toLocaleString('en-GB', {minimumFractionDigits: 2});
      }
    },
    eurPendingBalance() {
      if (this.balances) {
        let total = 0;
        this.balances.pendingBalance.forEach((item) => {
          if (item.currency === 'EUR') {
            total += item.value
          }
        });

        return (total / 100).toLocaleString('en-GB', {minimumFractionDigits: 2});
      }
    },
    eurOnHoldBalance() {
      if (this.balances) {
        let total = 0;
        this.balances.onHoldBalance.forEach((item) => {
          if (item.currency === 'EUR') {
            total += item.value
          }
        });

        return (total / 100).toLocaleString('en-GB', {minimumFractionDigits: 2});
      }
    },
    hasGbpBalances() {
      return this.gbpBalance > 0 || this.gbpOnHoldBalance > 0 || this.gbpPendingBalance > 0;
    },
    hasEurBalances() {
      return this.eurBalance > 0 || this.eurOnHoldBalance > 0 || this.eurPendingBalance > 0;
    }
  },
  mounted() {
    this.fetchBalances();
  },
};
</script>
