<template>
  <div class="mb-3">
    <h5 class="mb-3 fw-bold text-primary">
      Transaction History
    </h5>
    <busy :visible="loading" />
    <div v-if="!loading && transactions" class="card bg-light shadow-none">
      <div class="card-body">

        <div class="card">
          <table class="table mb-0"
                 v-if="transactions.length > 0">
            <thead>
              <th scope="col" style="border-top: 0px">Transaction Date</th>
              <th scope="col" style="border-top: 0px">Amount</th>
              <th scope="col" style="border-top: 0px">Order</th>
              <th scope="col" style="border-top: 0px">Instalment</th>
              <th scope="col" style="border-top: 0px">Client</th>
            </thead>
            <tbody>
              <tr v-for="t in transactions">
                <td>{{ t.event_date | formatDate }}</td>
                <td v-if="t.amount >= 0">{{t.currency.symbol}}{{ t.amount | formatCurrency }}</td>
                <td v-else class="text-danger">-{{t.currency.symbol}}{{ t.amount | formatCurrency }}</td>
                <td>
                    <router-link v-if="t.order_id" :to="'/orders/' + t.order_id">Order #{{t.order_id}}</router-link>
                </td>
                <td>
                    <router-link v-if="t.instalment_id" :to="'/orders/' + t.order_id">Instalment #{{t.instalment_id}}</router-link>
                </td>
                <td>
                  <router-link v-if="t.client_id" :to="'/clients/' + t.client_id">{{t.client.name}} <i>(#{{t.client_id}})</i></router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="card-body">
            <p class="text-center">
              No transactions found.
            </p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col my-auto">
                <button
                    class="btn btn-sm btn-light"
                    @click="fetchTransactions(pagination.first_page_url)"
                    :disabled="!pagination.first_page_url"
                >
                  First Page
                </button>

                <button
                    class="btn btn-sm btn-light ms-3"
                    @click="fetchTransactions(pagination.prev_page_url)"
                    :disabled="!pagination.prev_page_url"
                >
                  Previous
                </button>
              </div>
              <div class="col my-auto text-center">
                <p class="mb-0">
                  Page {{ pagination.current_page }} of
                  {{ pagination.last_page }}
                </p>
              </div>
              <div class="col my-auto text-end">
                <button
                    class="btn btn-sm btn-light cursor-pointer me-3"
                    @click="fetchTransactions(pagination.next_page_url)"
                    :disabled="!pagination.next_page_url"
                >
                  Next
                </button>

                <button
                    class="btn btn-sm btn-light cursor-pointer"
                    @click="fetchTransactions(pagination.last_page_url)"
                    :disabled="!pagination.last_page_url"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col text-center">
          <p><i class="far fa-info-circle fa-3x text-primary"></i></p>
          <p>No transactions found.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      transactions: null,
      pagination: {}
    };
  },
  methods: {
    fetchTransactions(paginationUrl) {
      const url = paginationUrl ?? process.env.VUE_APP_API_URL + "/settings/payments/adyen/transactions"
      this.$axios.get(url).then(({ data }) => {
        this.pagination = data;
        this.transactions = data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    }
  },
  mounted() {
    this.fetchTransactions();
  },
  filters: {
    formatCurrency(val) {
      return Math.abs(val / 100).toFixed(2);
    },
    formatDate(date) {
      return moment(date).local().format("LLL");
    },
  },
};
</script>
