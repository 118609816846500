<template>
  <div class="container-fluid" v-if="$can('manage payment settings')">
    <h4 class="fw-bold text-primary">Payment Processing</h4>
    <div class="row" style="min-height: 88vh">
      <div class="col-md-3 spark-settings-tabs">
        <payments-nav></payments-nav>
      </div>

      <!-- Tab cards -->
      <div class="col-md-9 ps-0">
        <stripe-transactions v-if="isStripeUser"/>
        <adyen-transactions
            v-if="user.clinic.adyen_integration &&
      user.clinic.adyen_integration.setup_status !== 'pending'" />
      </div>
    </div>
  </div>

</template>

<script>
import PaymentsNav from "@/views/areas/payments/partials/PaymentsNav";
import StripeTransactions from "./stripe-connect-partials/StripeTransactions";
import AdyenTransactions from "./partials/AdyenTransactions";

export default {
  props: [],
  data() {
    return {
      showStripeConnect: true
    }
  },
  computed: {
    isStripeUser() {
      return (this.$store.getters['auth/user'].clinic.stripe_integration_id > 0)
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  components: {
    StripeTransactions,
    AdyenTransactions,
    PaymentsNav,
  }
};
</script>


<style scoped>

</style>
