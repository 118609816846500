<template>
  <div class="card bg-light border-0 shadow-none h-100" style="height: 80vh">
      <div class="card-body">
        <adyen-balances class="mt-1"
                        v-if="user.clinic.adyen_integration &&
          user.clinic.adyen_integration.setup_status !== 'pending'" />
        <div class="nav-pills flex-column">
          <div v-if="$can('manage clinic')">
            <router-link
                class="nav-link px-2 pt-2"
                to="/payments"
                :class="path === '/payments' ? 'active text-white' : ''"
            >
              <div class="row w-100">
                <div class="col-1 my-auto">
                  <i class="far fa-store"></i>
                </div>
                <div class="col-auto text-start my-auto">Payments Setup</div>
              </div>
            </router-link>

            <hr v-if="path.includes('settings/clinic')" />
          </div>

          <router-link
              v-if="$can('view payment history')"
              class="nav-link px-2 pt-2"
              to="/payments/history"
              :class="path.includes('payments/history') ? 'active text-white' : ''"
          >
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-list"></i>
              </div>
              <div class="col-auto text-start my-auto">Transaction History</div>
            </div>
          </router-link>

          <router-link
              v-if="$can('view payment history')"
              class="nav-link px-2 pt-2"
              to="/payments/payouts"
              :class="path.includes('payments/payouts') ? 'active text-white' : ''"
          >
            <div class="row w-100">
              <div class="col-1 my-auto">
                <i class="far fa-money-bill-wave"></i>
              </div>
              <div class="col-auto text-start my-auto">Payouts</div>
            </div>
          </router-link>

        </div>
      </div>
    </div>
</template>

<script>
import AdyenBalances from "../partials/AdyenBalances";
export default {
  components: {AdyenBalances},
  props: [],
  computed: {
    path: function () {
      return this.$router.currentRoute.fullPath;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
};
</script>

<style scoped>
  .nav-link.active {
    color: white !important;
  }
</style>
