<template>
    <busy v-if="busy" />
    <div v-else>
      <div class="row">
          <div class="col">
            <h5 class="mb-3 fw-bold text-primary">Transactions (Stripe)</h5>
            <div class="card">
                <table class="table table-hover mb-0" v-if="transactions.length > 0">
                    <thead>
                    <tr>
                        <th scope="col" style="border-top: none;">Reference</th>
                        <th scope="col" style="border-top: none;">Amount</th>
                        <th scope="col" style="border-top: none;">Type</th>
                        <th scope="col" style="border-top: none;">Status</th>
                        <th scope="col" style="border-top: none;">Date</th>
                        <th scope="col" style="border-top: none;"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="cursor-pointer" v-for="transaction in transactions" v-if="transaction.type !== 'payout'">
                        <td>{{transaction.id.substring(5)}}...</td>
                        <td>£{{(transaction.amount / 100).toFixed(2)}}</td>
                        <td class="text-capitalize">{{transaction.type}}</td>
                        <td class="text-capitalize">{{transaction.status | formatStatus}}</td>
                        <td>{{transaction.created | formatUnixTime}}</td>
                        <td class="cursor-pointer"><i class="far fa-arrow-alt-square-down"></i></td>
                    </tr>
                    </tbody>
                </table>
                <div v-else class="card-body">
                    <div class="row">
                        <div class="col text-center">
                            <p><i class="far fa-info-circle fa-3x text-primary"></i></p>
                            <p>No transactions found.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="d-flex justify-content-between">
                  <button class="btn btn-light"
                          :disabled="!hasPrev"
                          @click="fetchStripeTransactions('prev')">
                    Prev Page
                  </button>
                  <button class="btn btn-light"
                          @click="fetchStripeTransactions('next')">
                    Next Page
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ["path", "user"],
  data() {
    return {
        busy: true,
        transactions: null,
        startingTransactionId: null, //pagination tracking
        hasMore: false,
        hasPrev: false,
    };
  },
  methods: {
    fetchStripeTransactions(direction=null){
      let url = process.env.VUE_APP_API_URL + '/settings/payments/fetch-stripe-transactions';

      if (direction === 'next') {
        this.hasPrev = true;
        const starting_after = this.transactions[this.transactions.length-1].id ?? this.startingTransactionId;
        url = this.updateQueryStringParameter(url, "starting_after", starting_after);
      } else if (direction === 'prev') {
        if (this.transactions[0]){
          url = this.updateQueryStringParameter(url, "ending_before", this.transactions[0].id);
        }
      }

      return this.$axios.get(url, {})
          .then(({data}) => {
              this.transactions = data.data;
              this.hasMore = data.has_more;
              this.busy = false;

              if (this.transactions[0]) {
                this.startingTransactionId = this.startingTransactionId ?? this.transactions[0].id;
                this.hasPrev = this.transactions[0].id !== this.startingTransactionId;
              }

          });
    },
    updateQueryStringParameter(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
      } else {
        return uri + separator + key + "=" + value;
      }
    },
  },
  mounted() {
      this.fetchStripeTransactions();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
    formatUnixTime(unixTime) {
        return moment.unix(unixTime).format("LLL");
    },
    formatUnixDate(unixTime) {
      return moment.unix(unixTime).format("LL");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    }
  },
};
</script>

<style>
</style>
